import httpUtil from "@/utils/httpUtil";

// 色卡本
/**获取列表 */
export const selectGoodsList =  params => httpUtil.post("/api/crmPc/colorCardBook/selectGoodsList", params);

/**保存列表 */
export const addGoodsInfo =  params => httpUtil.post("/api/crmPc/colorCardBook/addGoodsInfo", params);

/**修改列表 */
export const updateGoodsInfo =  params => httpUtil.post("/api/crmPc/colorCardBook/updateGoodsInfo", params);

/**删除列表 */
export const delGoodsInfo =  params => httpUtil.post("/api/crmPc/colorCardBook/delGoodsInfo", params);

/**发布列表 */
export const releaseGoods =  params => httpUtil.post("/api/crmPc/colorCardBook/releaseGoods", params);

// 模型-材质
/**发布模型 */
export const addModel =  params => httpUtil.post("/api/crmPc/colorCard/addModel", params);

/**模型分类查询 */
export const queryModel =  params => httpUtil.post("/api/crmPc/colorCardSort/queryModel", params);

/**材质分类查询 */
export const queryMaterial =  params => httpUtil.post("/api/crmPc/colorCardSort/queryMaterial", params);

/**材质分类天加 */
export const addMaterial =  params => httpUtil.post("/api/crmPc/colorCardSort/addMaterial", params);

/**修改模型、材质分类 */
export const update =  params => httpUtil.post("/api/crmPc/colorCardSort/update", params);

/**删除模型、材质分类 */
export const deletes =  params => httpUtil.post("/api/crmPc/colorCardSort/delete", params);

/**材质列表查询 */
export const colorCardQueryMaterial =  params => httpUtil.post("/api/crmPc/colorCard/queryMaterial", params);

/**分类列表查询 */
export const colorCardQueryModel =  params => httpUtil.post("/api/crmPc/colorCard/queryModel", params);

/**添加模型分类 */
export const colorCardSortAddModel =  params => httpUtil.post("/api/crmPc/colorCardSort/addModel", params);

/**添加材质 */
export const colorCardAddMaterial =  params => httpUtil.post("/api/crmPc/colorCard/addMaterial", params);