<template>
    <div class="mt-16">
        <el-card shadow="never">
            <el-button type="primary" @click="dialogVisible = true">添加模型</el-button>
            <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
                @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
                <template v-slot:table>
                    <!-- <el-table-column align="center" type="index" label="序号" /> -->
                    <el-table-column prop="materialId" align="center" label="编号" />
                    <el-table-column align="center" label="封面图" />
                    <el-table-column prop="name" align="center" label="名称" />
                    <el-table-column prop="sortName" align="center" label="分类" />
                    <el-table-column prop="rank" align="center" label="排序" />
                    <el-table-column prop="createTime" align="center" label="上传时间" />
                    <el-table-column prop="url" align="center" label="模型地址" />
                    <el-table-column align="center" label="操作">
                        <el-button type="text">设置</el-button>
                        <el-popconfirm class="ml-12" title="是否删除分类？">
                            <el-button slot="reference" type="text">删除</el-button>
                        </el-popconfirm>
                    </el-table-column>
                </template>
            </commonTable>
        </el-card>
        <el-dialog title="添加模型" :visible.sync="dialogVisible" width="50%" :modal-append-to-body="false"
            :destroy-on-close="true" center>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="78px">
                <el-form-item label="模型名称" prop="title">
                    <el-input v-model="ruleForm.name" placeholder="请输入模型名称"></el-input>
                </el-form-item>
                <el-form-item label="模型分类" prop="title">
                    <el-select v-model="ruleForm.sort" placeholder="请选择" style="width: 100%;">
                        <el-option v-for="item in classifyList" :key="item.sortId" :label="item.name" :value="item.sortId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="封面图" prop="title">
                    <el-upload :class="{
                        disabled: uploadDisabled,
                    }" ref="uploadIcon" :action="$store.state.uploadingUrl" list-type="picture-card"
                        :on-progress="handProgress" :on-success="handleSuccessCover" :on-remove="handleRemoveCover"
                        :file-list="fileList" :limit="1" accept=".png,.jpg">
                        <i class="el-icon-plus avatar-uploader-icon"></i>
                        <div slot="tip" class="el-upload__tip">
                            *格式png、jpg，大小不超过2MB。
                        </div>
                    </el-upload>
                </el-form-item>
                <el-form-item label="上传文件" prop="title">
                    <el-upload class="upload-demo" action="http://f3.ttkt.cc:17526//file/uploadZip"
                        :on-remove="handleRemoveZip" :limit="1" :on-success="handleSuccessZip" :file-list="fileListZip"
                        accept=".zip">
                        <el-button size="small" type="primary">点击上传</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传.zip压缩包，且不超过500mb</div>
                    </el-upload>
                </el-form-item>
                <el-form-item label="备注" prop="title">
                    <el-input type="textarea" :rows="3" v-model="ruleForm.content" placeholder="请输入备注"></el-input>
                </el-form-item>
                <el-form-item label="排序" prop="title">
                    <el-input type="number" v-model="ruleForm.rank" placeholder="数值越大排序越靠前"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="subMit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
  
<script>
import commonTable from "@/components/common/commonTable";
import { colorCardQueryMaterial, colorCardAddMaterial, queryMaterial } from "@/api/colorBook";
export default {
    components: {
        commonTable,
    },
    data() {
        return {
            dialogVisible: false,
            ruleForm: {},
            rules: {},
            fileList: [],
            fileListZip: [],
            classifyList: [],
            value: "",
            tableData: [],
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
        };
    },
    computed: {
        uploadDisabled() {
            return this.fileList.length !== 0;
        }
    },
    mounted() {
        this.getData();
        this.getClassify();
    },
    methods: {
        subMit() {
            console.log('提交数据：', this.ruleForm);
            colorCardAddMaterial(this.ruleForm).then(res => { })
        },
        getClassify() {
            let data = {
                pageNum: 1,
                pageSize: 100
            }
            queryMaterial(data).then(res => {
                this.classifyList = res.data.pageInfo.list;
            })
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.getData();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        getData() {
            let data = {
                pageNum: this.currentPage,
                pageSize: this.pageSize,
            }
            colorCardQueryMaterial(data).then(res => {
                this.tableData = res.data.pageInfo.list;
                this.total = res.data.pageInfo.total;
            })
        },
        /**@method 上传时回调 */
        handProgress(event, file, fileList) {
            if (this.isUpdate) {
                this.$message.closeAll();
                this.$message({
                    message: "正在上传",
                });
            }
            this.isUpdate = false;
        },
        // 封面
        handleSuccessCover(res, file, fileList) {
            this.fileList = fileList;
            this.$message.closeAll();
            this.$message({
                message: "上传成功",
                type: "success",
            });
            this.ruleForm.cover = `https://cdn-oss.jquen.com/${res.data}`;
        },
        handleRemoveCover(file, fileList) {
            console.log(file, fileList);
            this.ruleForm.cover = "";
            this.fileList = [];
        },
        handleSuccessZip(res, file, fileList) {
            this.fileListZip = fileList;
            this.$message.closeAll();
            this.$message({
                message: "上传成功",
                type: "success",
            });
            this.ruleForm.modelName = res.data;
        },
        handleRemoveZip(file, fileList) {
            this.fileListZip = [];
            this.ruleForm.modelName = '';
        },
    },
};
</script>
  
<style lang="scss" scoped>
::v-deep .disabled .el-upload--picture-card {
    display: none;
}

::v-deep .upload-demo {
    .el-upload {
        border: none;
        height: auto;
        display: flex;
    }
}
</style>