<template>
    <div class="mt-16">
        <el-card shadow="never">
            <el-button type="primary" @click="dialogVisible = true">添加分类</el-button>
            <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
                @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
                <template v-slot:table>
                    <!-- <el-table-column align="center" type="index" label="序号" /> -->
                    <el-table-column prop="sortId" align="center" label="编号" />
                    <el-table-column prop="name" align="center" label="分类名称" />
                    <el-table-column prop="userName" align="center" label="操作">
                        <el-button type="text" >设置</el-button>
                        <el-popconfirm class="ml-12" title="是否删除分类？">
                            <el-button slot="reference" type="text">删除</el-button>
                        </el-popconfirm>
                    </el-table-column>
                </template>
            </commonTable>
        </el-card>
        <el-dialog title="添加分类" :visible.sync="dialogVisible" width="50%" :modal-append-to-body="false"
            :destroy-on-close="true" center>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="78px">
                <el-form-item label="分类名称" prop="title">
                    <el-input v-model="ruleForm.name" placeholder="请输入分类名称"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="subMit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
  
<script>
import commonTable from "@/components/common/commonTable";
import { queryMaterial, addMaterial } from "@/api/colorBook";
export default {
    components: {
        commonTable,
    },
    data() {
        return {
            dialogVisible: false,
            ruleForm: {},
            rules: {},
            options: [
                {
                    value: "选项1",
                    label: "自定义媒体页",
                },
                {
                    value: "选项2",
                    label: "短视频",
                },
            ],
            value: "",
            tableData: [],
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        handleSizeChange(val) {
            this.pageSize = val;
            this.getData();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        getData() {
            let data = {
                pageNum: this.currentPage,
                pageSize: this.pageSize,
            }
            queryMaterial(data).then(res => {
                this.tableData = res.data.pageInfo.list;
                this.total = res.data.pageInfo.total;
            })
        },
        subMit() {
            console.log('提交数据：', this.ruleForm);
            addMaterial(this.ruleForm).then(res => {
                if (res) {
                    this.$message.success('保存成功！');
                    this.dialogVisible = false;
                    this.getData();
                    this.ruleForm = {};
                }
            })
        },
    },
};
</script>
  
<style></style>