<template>
    <div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="色卡本列表" name="first">
          <list v-if="activeName == 'first'" />
        </el-tab-pane>
        <el-tab-pane label="草稿箱" name="second"> 
          <list v-if="activeName == 'second'" :drafts='true' />
        </el-tab-pane>
        <el-tab-pane label="模型列表" name="three"> 
          <modelList v-if="activeName == 'three'" :drafts='true' />
        </el-tab-pane>
        <el-tab-pane label="模型分类" name="four"> 
          <modelClassify v-if="activeName == 'four'" :drafts='true' />
        </el-tab-pane>
        <el-tab-pane label="材质列表" name="five"> 
          <materialList v-if="activeName == 'five'" :drafts='true' />
        </el-tab-pane>
        <el-tab-pane label="材质分类" name="six"> 
          <materialClassify v-if="activeName == 'six'" :drafts='true' />
        </el-tab-pane>
      </el-tabs>
    </div>
  </template>
  
  <script>
  import list from './components/list';
  import modelList from './components/modelList';
  import modelClassify from './components/modelClassify';
  import materialList from './components/materialList';
  import materialClassify from './components/materialClassify';
  export default {
    name: "spike",
    components:{
      list,
      materialClassify,
      modelList,
      materialList,
      modelClassify
    },
    data() {
      return {
        activeName: "first",
      }
    },
    methods: {
      handleClick(tab) {
        const { name } = tab;
        this.activeName = name;
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  </style>